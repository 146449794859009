/* You can add global styles to this file, and also import other style files */

@import '~@fortawesome/fontawesome-free/css/all.min.css';


html, body { height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent scrolling from body */ }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
